<template>
  <div>
    <div style="text-align: center;">
      <img
        style="max-width: 300px; margin-top: 50px;"
        src="https://digital.seamlesssource.com/img/seamless-source-banner.878b4d52.png"
      />
    </div>
    <div class="wrapper">
      <div style="text-align: center;">
        <p>Hello,</p>
        <p>
          We are pleased to inform you of the recent upgrades made to our
          existing platform, introducing a set of advanced features aimed at
          accelerating your business. To access these enhancements, kindly login
          using our new URL:
          <a href="https://digital.seamlesssource.com/"
            >https://digital.seamlesssource.com/</a
          >.
        </p>

        <p>
          Should you encounter any issues or require additional information,
          please do not hesitate to reach out to our team:
        </p>

        <p>Email: info@seamlesssource.com</p>
        <p style="margin-top: -13px;">Phone: +44 73 9958 5015</p>

        <p>
          We appreciate your continued partnership and look forward to working
          with you.
        </p>

        <p>Thank you and Best Regards,</p>

        <p>Chathura Sudharshan</p>

        <p>Founder</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UnderMaintenance",
  data: () => ({
    logoImg: require(`../assets/seamless-source-banner.png`),
  }),
};
</script>

<style scoped>
.wrapper {
  margin-top: 50px;
  padding-left: 20px;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .wrapper {
    padding-left: 300px;
    padding-right: 300px;
  }
}

div {
  display: block;
  font-size: 16px;
  font-family: Times;
  color: black;
  font-weight: 500;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
  text-decoration: underline;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

body {
  display: block;
  margin: 8px;
}

html {
  -webkit-locale: "en";
}

:root {
  view-transition-name: root;
}

html {
  display: block;
}
</style>
